import Vue from 'vue'

// axios
import axios from 'axios'
import store from '@/store/index'
// eslint-disable-next-line import/no-mutable-exports
const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    baseURL: 'http://3.73.168.59/api',
    // timeout: 1000,
    headers: { Authorization: `Bearer ${store.state.auth.token}` },
  })

Vue.prototype.$http = axiosIns

export default axiosIns
